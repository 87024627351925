import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningIcon from '@mui/icons-material/Warning';
import ApiIcon from '@mui/icons-material/Api';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAuth } from '../auth/AuthProvider';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HistoryIcon from '@mui/icons-material/History';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import MapIcon from '@mui/icons-material/Map';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import theme from '../theme';

import Profile from '../profile';
import ListMenuPPI from '../PPI/listMenu';
import ListMenUEmpresas from '../Empresa/listMenu';
import ListMenuReport from '../Report/listMenuReport';
export default function MainListItems() {
  const handlerLoc = (item) => JSON.parse(localStorage.getItem(item)) || false

  const [open, setOpen] = React.useState({
    userMenu: handlerLoc('userMenu'),
    backupMenu: handlerLoc('backupMenu'),
    empresaMenu: handlerLoc('empresaMenu'),
    reportMenu: handlerLoc('reportMenu')
  });
  const auth = useAuth()

  const handleClick = (item, value) => {
    setOpen(open => ({ ...open, [item]: !value }))
    localStorage.setItem(item, !value)


  };
  return (
    <List>
      {auth.hasPerm('problem.get') ?
        <Link to={'/'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>
          <ListItemButton  >
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary="Alarmes" />
          </ListItemButton>
        </Link>
        : ''}

      {auth.hasPerm('api_zabbix.get') ?
        <Link to={'/api'} style={{ textDecoration: 'none', 
        backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
        color: theme.palette.mode=='dark' ? grey[100] : grey[900]
      
      }}>

          <ListItemButton  >
            <ListItemIcon>
              <ApiIcon />
            </ListItemIcon>
            <ListItemText primary="APIs" />
          </ListItemButton>
        </Link>
        : ''}
     
      {auth.hasPerm('user.get') ?
        <>
          <ListItemButton onClick={() => handleClick('userMenu', open.userMenu)}
            sx={{ 
            backgroundColor: open?.userMenu ?  theme.palette.mode=='dark' ? grey[900] : grey[100]: theme.palette.mode=='dark' ? grey[900] : grey[100],
            color: theme.palette.mode=='dark' ? grey[100] : grey[900]
            }}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Administração"
             />
            {open?.userMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton >
          <Collapse in={open?.userMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ 
                pl: 1,
                backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[200] : grey[900]
             }} >
              <Link to={'/user'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>
                <ListItemButton  >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Usuários" />
                </ListItemButton>
              </Link>
              <Link to={'/user/online'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>

                <ListItemButton  >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Usuários online" />
                </ListItemButton>
              </Link>
              {auth.hasPerm('role.get') ?
                <Link to={'/user/role'} style={{ textDecoration: 'none', 
                backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
                color: theme.palette.mode=='dark' ? grey[100] : grey[900]
              
              }}>
                  <ListItemButton  >
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cargos" />
                  </ListItemButton>
                </Link>

                : ''}
            </List>

          </Collapse>
        </>
        : ''}
        <ListMenUEmpresas/>
         <ListMenuReport/>

        <Profile/>
        <ListMenuPPI/>
      {auth.hasPerm('problem.get') ?
        <>
        <Link to={'/map'} target="_blank" style={{ textDecoration: 'none', color: 'white',
      
      backgroundColor: theme.palette.mode=='dark' ? open?.userMenu ? grey[900] : grey[100] : grey[900], 
      color: theme.palette.mode=='dark' ? grey[100] : grey[900]
      
    }}>

          <ListItemButton  >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Diagramas de Rede" />
          </ListItemButton>
        </Link>

       
          </>
        : ''}
        {/* {auth.hasPerm('token.get') ? 
         <Link to={'/token'} style={{ textDecoration: 'none', color: 'white',
            
         backgroundColor: theme.palette.mode=='dark' ? open?.userMenu ? grey[900] : grey[100] : grey[900], 
         color: theme.palette.mode=='dark' ? grey[100] : grey[900]
         
       }}>

         <ListItemButton  >
           <ListItemIcon>
             <PasswordIcon />
           </ListItemIcon>
           <ListItemText primary="Tokens API" />
         </ListItemButton>
       </Link> : ""} */}
    </List>
  );
}
