import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useAuth } from '../auth/AuthProvider';
import { Link } from 'react-router-dom';
import theme from '../theme';
import { grey } from '@mui/material/colors';
import { ExpandLess, ExpandMore, Warning } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const handlerLoc = (item) => JSON.parse(localStorage.getItem(item)) || false
export default function ListMenu(props) {
const [open, setOpen] = React.useState({
    ppiMenu: handlerLoc('ppiMenu')
  });

const handleClick = (item, value) => {
    setOpen(open => ({ ...open, [item]: !value }))
    localStorage.setItem(item, !value)

  };

    const auth = useAuth();
    return (
        <>
{auth.hasPerm('empresa.get') ?
        <>
          <ListItemButton onClick={() => handleClick('empresaMenu', open.empresaMenu)}
           sx={{ 
            backgroundColor: open?.userMenu ?  theme.palette.mode=='dark' ? grey[900] : grey[100]: theme.palette.mode=='dark' ? grey[900] : grey[100],
            color: theme.palette.mode=='dark' ? grey[100] : grey[900]
            }}>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary="Empresas" />
            {open?.empresaMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open?.empresaMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ 
              pl:1,
              backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
              color: theme.palette.mode=='dark' ? grey[200] : grey[900]
              }} >
              <Link to={'/empresa'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>
                <ListItemButton  >
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Empresas" />
                </ListItemButton>
              </Link>
              {auth.hasPerm('empresa.get') ?
                <>
                 
                  <Link to={'/empresa/operadora'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>                    <ListItemButton component="a" href={'/empresa/operadora'} >
                      <ListItemIcon>
                        <WifiCalling3Icon />
                      </ListItemIcon>
                      <ListItemText primary="Operadoras" />
                    </ListItemButton>
                  </Link>
                </>
                : ''}
            </List>
          </Collapse>
        </>
        : ''}
        </>
  )
}