import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Empresa from './Empresa'
import Contato from './Contatos/'
import Servico from './Servicos'
import Acesso from './Acessos';
import Link from './Links'
import ControleHora from './ControleHora'
import ControleHoraAdicional from './ControleHoraAdicional'
import Token from './Token'
import { useAuth } from '../../auth/AuthProvider'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const auth = useAuth()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')

  return (
    <Box sx={{ width: '100%' }}>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Dados" {...a11yProps(0)} />
          {props.id ? <Tab label="Contatos" {...a11yProps(1)} />: null}
          {props.id ? <Tab label="Links/Upstreams" {...a11yProps(2)} />: null}
          {props.id ? <Tab label="Acessos" {...a11yProps(3)} />: null}
          {/* {props.id ? <Tab label="Serviços Contratados" {...a11yProps(4)} />: null}
          {props.id ? <Tab label="Controle de Horas" {...a11yProps(5)} />: null}
          {props.id ? <Tab label="Controle de Horas Adicional" {...a11yProps(6)} />: null}
          {props.id  && auth.hasPerm("token.empresa.get") ? <Tab label="Tokens" {...a11yProps(7)} />: null} */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Empresa {...props}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Contato empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Link empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Acesso empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel> 
      <TabPanel value={value}  index={4}>
        <Servico empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
      <TabPanel value={value}  index={5}>
        <ControleHora empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
      <TabPanel value={value}  index={6}>
        <ControleHoraAdicional empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
      <TabPanel value={value}  index={7}>
        <Token empresa_id = {props.id} showNotify={props.showNotify} />
      </TabPanel>
       
    </Box>
  );
}

